<template>
  <a-list size="large" bordered :data-source="data">
    <template #renderItem="{ item }">
      <a-list-item>{{ item }}</a-list-item>
    </template>
    <!-- <template #header>
      <div>Header</div>
    </template>
    <template #footer>
      <div>Footer</div>
    </template> -->
  </a-list>
</template>

<script>
import { defineComponent } from 'vue';
const data = [

];
export default defineComponent({
  setup() {
    return {
      data,
    };
  },
});
</script>

<style scoped lang='less'>
.ant-list-bordered{
  border: none;
}
</style>
